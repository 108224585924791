import "./form.scss";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const toastOptions = {
  position: "top-center",
  autoClose: 4000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  transition: Slide,
  bodyClassName: "toast-font-size",
};

const Form = () => {
  const form = useRef();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async () => {
    try {
      await emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_USER_ID
        )
        .then(
          (result) => {
            toast(
              "Thank you for contacting us. We will get back to you shortly.",
              toastOptions
            );
            reset();
          },
          (error) => {
            toast(
              "Sorry. Couldn't send your Data. Try again later",
              toastOptions
            );
          }
        );
    } catch (e) {
      // console.log(e);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} ref={form}>
        <div className="form--group">
          <label>name:</label>
          <input
            type="text"
            name="name"
            {...register("name", {
              required: {
                value: true,
                message: "Please enter your name",
              },
              maxLength: {
                value: 30,
                message: "Please use 30 characters or less",
              },
            })}
          />
          {errors.name && (
            <div className="errorMessage">{errors.name.message}</div>
          )}
        </div>
        <div className="form--group">
          <label>Email:</label>
          <input
            type="email"
            name="email"
            {...register("email", {
              required: true,
              pattern:
                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            })}
          />
          {errors.email && (
            <div className="errorMessage">enter valid email address</div>
          )}
        </div>
        <div className="form--group">
          <label>phone:</label>
          <input
            type="phone"
            name="phone"
            {...register("phone", {
              required: {
                value: true,
                message: "Please enter your valid mobile number",
              },
            })}
          />
          {errors.phone && (
            <div className="errorMessage"> {errors.phone.message}</div>
          )}
        </div>
        <div className="form--group">
          <label>message:</label>
          <textarea
            type="phone"
            name="message"
            {...register("message", {
              required: true,
            })}
          ></textarea>
          {errors.message && (
            <div className="errorMessage">Please enter some message</div>
          )}
        </div>
        <button className="btn" type="submit">
          Contact Us
        </button>
      </form>
    </>
  );
};

export default Form;
