import "./nav.scss";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MdClose, MdMenu, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useEffect, useState } from "react";

const Nav = () => {
  const [nav1, setNav1] = useState(false);
  const [nav2, setNav2] = useState(false);
  const [nav3, setNav3] = useState(false);
  const [nav4, setNav4] = useState(false);
  const [nav5, setNav5] = useState(false);
  const [menu, setMenu] = useState(false);

  const location = useLocation();
  useEffect(() => {
    setMenu(false);
  }, [location.pathname]);

  return (
    <div className="nav">
      <Link to={"/"} className="logo">
        <img src="/assets/logo.png" alt="healthstore" />
        <div className="logo--name">
          <span>PLUS971</span>
          <span>health store</span>
        </div>
      </Link>
      {menu ? (
        <MdClose className="menu" onClick={() => setMenu(false)} />
      ) : (
        <MdMenu className="menu" onClick={() => setMenu(true)} />
      )}
      <ul className={menu ? "nav--links open" : "nav--links "}>
        <li className="nav--link">
          <NavLink
            to={"/"}
            className={({ isActive }) => (isActive ? "navActive" : "")}
            end
          >
            Home
          </NavLink>
        </li>
        <li className="nav--link" onMouseLeave={() => setNav5(false)}>
          <span
            onMouseEnter={() => setNav5(true)}
            onClick={() => setNav5(!nav5)}
            className="nav--heading"
          >
            Weight Loss
            <MdOutlineKeyboardArrowDown />
          </span>
          <ul className={nav5 ? "nav--link__sub active" : "nav--link__sub"}>
            <li>
              <NavLink
                to="/products/rybelsus"
                className={({ isActive }) => (isActive ? "subNavActive" : "")}
              >
                rybelsus
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/products/ozempic"
                className={({ isActive }) => (isActive ? "subNavActive" : "")}
              >
                ozempic
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/products/saxenda"
                className={({ isActive }) => (isActive ? "subNavActive" : "")}
              >
                saxenda
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="nav--link" onMouseLeave={() => setNav1(false)}>
          <span
            onMouseEnter={() => setNav1(true)}
            onClick={() => setNav1(!nav1)}
            className="nav--heading"
          >
            General Health
            <MdOutlineKeyboardArrowDown />
          </span>
          <ul className={nav1 ? "nav--link__sub active" : "nav--link__sub"}>
            <li>
              <NavLink
                to="/products/ulcer-self-test-kit"
                className={({ isActive }) => (isActive ? "subNavActive" : "")}
              >
                Stomach ulcer self test kit
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/products/kidney-health-test-kit"
                className={({ isActive }) => (isActive ? "subNavActive" : "")}
              >
                Kidney health self test kit
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/products/vitamin-d-self-test-kit"
                className={({ isActive }) => (isActive ? "subNavActive" : "")}
              >
                vitamin D self test kit
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/products/drugs-and-alcohol-self-test-kit"
                className={({ isActive }) => (isActive ? "subNavActive" : "")}
              >
                drugs and alcohol self test kit
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/products/iron-deficiency-self-test-kit"
                className={({ isActive }) => (isActive ? "subNavActive" : "")}
              >
                Iron Deficiency Self Test Kit
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/products/thyroid-self-test-kit"
                className={({ isActive }) => (isActive ? "subNavActive" : "")}
              >
                thyroid self test kit
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="nav--link" onMouseLeave={() => setNav2(false)}>
          <span
            onMouseEnter={() => setNav2(true)}
            onClick={() => setNav2(!nav2)}
            className="nav--heading"
          >
            Sexual Health
            <MdOutlineKeyboardArrowDown />
          </span>
          <ul className={nav2 ? "nav--link__sub active" : "nav--link__sub"}>
            <li>
              <NavLink
                to="/products/uti-self-test-kit"
                className={({ isActive }) => (isActive ? "subNavActive" : "")}
              >
                UTI self test kit
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/products/HIV-Self-Test-Kit"
                className={({ isActive }) => (isActive ? "subNavActive" : "")}
              >
                HIV self test kit
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="nav--link" onMouseLeave={() => setNav3(false)}>
          <span
            onMouseEnter={() => setNav3(true)}
            onClick={() => setNav3(!nav3)}
            className="nav--heading"
          >
            Tumour marker
            <MdOutlineKeyboardArrowDown />
          </span>
          <ul className={nav3 ? "nav--link__sub active" : "nav--link__sub"}>
            <li>
              <NavLink
                to="/products/bowel-health-self-test-kit"
                className={({ isActive }) => (isActive ? "subNavActive" : "")}
              >
                bowel health self test kit
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/products/prostate-health-self-test-kit"
                className={({ isActive }) => (isActive ? "subNavActive" : "")}
              >
                prostate health self test kit
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="nav--link" onMouseLeave={() => setNav4(false)}>
          <span
            onMouseEnter={() => setNav4(true)}
            onClick={() => setNav4(!nav4)}
            className="nav--heading"
          >
            fertility
            <MdOutlineKeyboardArrowDown />
          </span>
          <ul className={nav4 ? "nav--link__sub active" : "nav--link__sub"}>
            <li>
              <NavLink
                to="/products/menopause-self-test-kit"
                className={({ isActive }) => (isActive ? "subNavActive" : "")}
              >
                menopause self test kit
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/products/ovulation-self-test-kit"
                className={({ isActive }) => (isActive ? "subNavActive" : "")}
              >
                Ovulation Self Test Kit
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/products/pregnancy-self-test-kit"
                className={({ isActive }) => (isActive ? "subNavActive" : "")}
              >
                Pregnancy Self Test Kit
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/products/male-fertility-self-test-kit"
                className={({ isActive }) => (isActive ? "subNavActive" : "")}
              >
                Male Fertility Self Test Kit
              </NavLink>
            </li>
          </ul>
        </li>

        <li className="nav--link">
          <a href="#contact">contact</a>
        </li>
      </ul>
    </div>
  );
};

export default Nav;
