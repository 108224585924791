import "./homeProducts.scss";
import { products } from "../../data";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";

import { Autoplay, FreeMode, Navigation } from "swiper";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";

const HomeProducts = () => {
  return (
    <section className="homeProducts" id="products">
      <h2>our products</h2>

      <Swiper
        slidesPerView={1}
        spaceBetween={40}
        freeMode={true}
        breakpoints={{
          450: {
            slidesPerView: 2,
          },
          750: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        }}
        modules={[FreeMode, Navigation, Autoplay]}
        loop={true}
        className="homeProducts--swiper"
        navigation={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: true,
        }}
      >
        {products.map((item) => (
          <SwiperSlide key={item.name}>
            <div className="slide">
              <div className="img">
                <img src={item.image} alt={item.name} />
              </div>
              <div className="name">
                <h3>{item.name}</h3>
                {item.subname && <p>{item.subname}</p>}
              </div>
              <ul>
                {item.description.map((el, i) => (
                  <li key={i}>
                    <FaCheck className="icon" /> {el}
                  </li>
                ))}
              </ul>
              <Link to={item.link}>
                <button className="btn">More Info</button>
              </Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default HomeProducts;
