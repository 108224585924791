import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer--container">
      <div className="footer">
        <div className="row row1">
          <a href="tel:+971551927293" className="box">
            <div className="icon">
              <img src="/assets/footer-icon4.svg" alt="call us" />
            </div>
            <div className="box--right">
              <p className="heading">Mobile</p>
              <p>+971 55 192 7293</p>
            </div>
          </a>

          <a href="mailto:sales@plus971healthstore.com" className="box">
            <div className="icon">
              <img src="/assets/footer-icon2.svg" alt="call us" />
            </div>
            <div className="box--right">
              <p className="heading">Email</p>
              <p>sales@plus971healthstore.com</p>
            </div>
          </a>
          <div className="box">
            <div className="icon">
              <img src="/assets/footer-icon1.svg" alt="call us" />
            </div>
            <div className="box--right">
              <p className="heading">Location</p>
              <p>Dubai Airport Freezone, UAE</p>
            </div>
          </div>
        </div>
        <div className="row row2">
          <div className="col col1">
            <div className="logo">
              <img src="/assets/logo.png" alt="healthstore" />
              <div className="logo--name">
                <span>PLUS971</span>
                <span>health store</span>
              </div>
            </div>
            <p>
              We are dedicated to providing you with a wide range of
              high-quality products to support your journey towards a healthier
              lifestyle. Our store is a haven for health enthusiasts, offering a
              carefully curated selection of weight loss treatments to cater to
              your specific needs.
            </p>
            <p className="note">
              Note: There are some products on this website which we cannot sell
              to mainland customers, But we are happy to refer you
              to local pharmacy.
            </p>
            <div className="copyright">
              Copyright © 2023 | plus971HealthStore
            </div>
          </div>
          <div className="col">
            <h3>Social Media</h3>
            <div className="socialmedia">
              <a href="https://www.instagram.com/plus971healthstore/">
                <FaInstagram className="icon" />
              </a>
              <a href="https://www.facebook.com/plus971healthstore">
                <FaFacebookF className="icon" />
              </a>
              <a href="https://twitter.com/971healthstore">
                <FaTwitter className="icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
