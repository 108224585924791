import "./contact.scss";
import Form from "./form/Form";

const Contact = () => {
  return (
    <div className="contact--container" id="contact">
      <div className="contact">
        <div className="left">
          <h2>Take Charge Of Your Health Today</h2>
          <p>
            Contact us now to take the first step towards a healthier you. Our
            friendly staff is ready to assist you and ensure that you receive
            the right health treatments tailored to your requirements. Don't
            wait any longer - reach out to us today and start your journey
            towards improved health and vitality.
          </p>
        </div>
        <div className="right">
          <Form />
        </div>
      </div>
    </div>
  );
};

export default Contact;
