import { Outlet } from "react-router-dom";
import { MdWhatsapp } from "react-icons/md";
import { ToastContainer } from "react-toastify";

const RootLayout = () => {
  return (
    <>
      <ToastContainer />
      <div className="sticky-whatsapp">
        <a href="https://wa.me/+971551927293" aria-label="whatsapp">
          <MdWhatsapp className="whatsapp-icon" />
        </a>
      </div>

      <Outlet />
    </>
  );
};

export default RootLayout;
