import "./weightloss.scss";

const Weightloss = () => {
  return (
    <div className="weightloss--container">
      <div className="weightloss">
        <section className="section">
          <div className="left">
            <h2>optimising weight loss</h2>
            <p>3 essential aspects in what brings effective weight loss.</p>
            <div className="image">
              <img src="/assets/section.png" alt="balanced diet" />
            </div>
          </div>
          <div className="right">
            <div className="section--row section--row1 ">
              <span className="element ">1</span>
              <h3>weight loss medication</h3>
              <p>
                Our clinical team have the expertise and experience in
                prescribing the appropriate weight loss medication based on your
                individual needs to help you reach your weight loss goals.
              </p>
            </div>
            <div className="section--row section--row2">
              <span className="element ">2</span>
              <h3>Balanced Diet</h3>
              <p>
                Maintaining a healthy diet is essential for overall well-being
                and can greatly support weight loss efforts. When combined with
                weight loss injections, it can create a powerful synergy to aid
                in achieving weight loss goals.
              </p>
            </div>
            <div className="section--row">
              <div className="element ">3</div>
              <h3>appropriate exercise</h3>
              <p>
                Exercise is a crucial component of a healthy lifestyle, and when
                combined with effective weight loss strategies like our weight
                loss treatments, it can produce remarkable results.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Weightloss;
