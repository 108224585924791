import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./headerSwiper.scss";

import { TbHealthRecognition } from "react-icons/tb";
import { FaPills, FaWeight } from "react-icons/fa";
import "swiper/css";
import "swiper/css/pagination";

import {  Autoplay, Pagination } from "swiper";

const slides = [
  {
    title: "Weight Loss Treatments",
    img: "/assets/hs33.png",
    tag: "Your weight loss experts in UAE",
  },
  {
    title: "Weight Loss Injections",
    img: "/assets/hs11.png",
    tag: "Take weight loss medication",
  },

  {
    title: "Weight Loss Tablets",
    img: "/assets/products/Rybelsus-plus-strip-tablets.png",
    tag: "Get Health test kit at your doorstep",
  },
];

const HeaderSwiper = () => {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class=${className} ></span>`;
    },
  };

  return (
    <Swiper
      // effect={"fade"}
      pagination={pagination}
      loop={true}
      modules={[ Autoplay, Pagination]}
      className="headerSwiper"
      speed={3000}
      autoplay={{
        delay: 12000,
        disableOnInteraction: false,
      }}
    >
      {slides.map((e) => (
        <SwiperSlide key={e.title}>
          <div className="slide slide1">
            <h1>{e.title}</h1>

            <div className="content">
              <div className="col col1">
                <h3>Online Medical Supplies</h3>
                <h3 className="col-left">{e.tag}</h3>

                <a href="#products">
                  <button className="btn btn--main">
                    View Products <span>&rarr;</span>
                  </button>
                </a>
              </div>
              <div className="col col2">
                {/* <img src="/assets/hbg.png" alt="bg" className="bg" /> */}
                <div className="img">
                  <img src={e.img} alt="weight loss pills" />
                </div>
              </div>
              <div className="col col3">
                <div className="row">
                  <div className="row--left">
                    <FaWeight className="icon" />
                  </div>
                  <div className="row--right">
                    <h3>weight loss Treatments</h3>
                    <p>unlock the path to a healthier, fitter you!</p>
                  </div>
                </div>
                <div className="row">
                  <div className="row--left">
                    <FaPills className="icon" />
                  </div>
                  <div className="row--right">
                    <h3>Optimize weight loss journey</h3>
                    <p>effective and practical weight loss solutions</p>
                  </div>
                </div>
                <div className="row">
                  <div className="row--left">
                    <TbHealthRecognition className="icon" />
                  </div>
                  <div className="row--right">
                    <h3>Self Test Kits</h3>
                    <p>
                      Convenient and accessible tools for personal health
                      screening at home
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HeaderSwiper;
