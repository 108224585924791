import "./service.scss";
import { SlChemistry } from "react-icons/sl";
import { GiThreeLeaves } from "react-icons/gi";
import { FaShippingFast, FaBox, FaHandshake } from "react-icons/fa";
import { BsFillHeartPulseFill } from "react-icons/bs";

const Services = () => {
  return (
    <div className="box--container">
      <div className="box--section">
        <div className="item">
          <div className="icon--container">
            <BsFillHeartPulseFill className="icon" />
          </div>
          <h3>Safe and reliable</h3>
          <p>
            We prioritize your safety and ensure that our products are free from
            harmful additives or ingredients.{" "}
          </p>
        </div>
        <div className="item">
          <div className="icon--container">
            <SlChemistry className="icon" />
          </div>
          <h3>clinically studied</h3>
          <p>
            providing you with scientifically validated solutions for your
            health and well-being
          </p>
        </div>
        <div className="item">
          <div className="icon--container">
            <GiThreeLeaves className="icon" />
          </div>
          <h3>vegetarian friendly</h3>
          <p>
            prioritizing your health needs while aligning with your dietary
            choices
          </p>
        </div>
        <div className="item">
          <div className="icon--container">
            <FaHandshake className="icon" />
          </div>
          <h3>Professional Guidance</h3>
          <p>
            Our team of professionals is dedicated to assisting you every step
            of the way, helping you achieve sustainable results.
          </p>
        </div>
        <div className="item">
          <div className="icon--container">
            <FaShippingFast className="icon" />
          </div>
          <h3>Fast shipping</h3>
          <p>
            Get your essential medical supplies swiftly delivered to your
            doorstep
          </p>
        </div>
        <div className="item">
          <div className="icon--container">
            <FaBox className="icon" />
          </div>
          <h3>Secure and tracked delivery</h3>
          <p>
            ensuring your health and well-being reach your doorstep with utmost
            care
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
