// products is card datya in the home page
export const products = [
  {
    name: "rybelsus",
    subname: "(Semaglutide Tablets)",
    image: "/assets/products/Rybelsus-plus-strip-tablets.png",

    description: [
      "weight loss treatment",
      "Rybelsus is a once daily tablet pre-filled with Semaglutide",
      "Contact us for wholesale orders",
    ],
    link: "/products/rybelsus",
  },
  {
    name: "ozempic",
    image: "/assets/products/ozempic-1.png",
    subname: "(Semaglutide Injections)",
    description: [
      "weight loss treatment",
      "Ozempic is a once weekly injection pre-filled with Semaglutide",
      "Contact us for wholesale orders",
    ],
    link: "/products/ozempic",
  },
  {
    name: "saxenda",
    subname: "(Liraglutide)",
    image: "/assets/products/saxenda1n.png",

    description: [
      "weight loss treatment",
      "Saxenda (Liraglutide) is pre-filled a once-daily injection",
      "Contact us for wholesale orders",
    ],
    link: "/products/saxenda",
  },
  {
    name: "Flowflex Influenza Test Kit",
    image: "/assets/products/kit1.webp",

    description: [
      "Quick testing for influenza A or B.",
      "Collection method: Nasal swab",
      "Contact us for wholesale orders",
    ],
    link: "/products/influenza-test-kit",
  },
  {
    name: "Kidney Health Self Test Kit",
    image: "/assets/products/kit3.webp",

    description: [
      "Quick testing for kidney malfunction and abnormalities",
      "Collection method: Urine sample",
      "Contact us for wholesale orders",
    ],
    link: "/products/kidney-health-test-kit",
  },
  {
    name: "Vitamin D Self Test Kit",
    image: "/assets/products/kit4.webp",

    description: [
      "help to identify vitamin D deficiency.",
      "Collection method: Bl0od sample",
      "Contact us for wholesale orders",
    ],
    link: "/products/vitamin-d-self-test-kit",
  },
  {
    name: "HIV Self Test Kit",
    image: "/assets/products/kit5.webp",

    description: [
      "Whether the human immunodeficiency virus has been detected in your blood.",
      "Collection method: Blood sample",
      "Contact us for wholesale orders",
    ],
    link: "/products/HIV-Self-Test-Kit",
  },
  {
    name: "Thyroid Self Test Kit",
    image: "/assets/products/kit6.webp",

    description: [
      "Whether the levels of FSH in your blood are elevated, a possible indicator of hypothyroidism.",
      "Collection method: Blood sample",
      "Contact us for wholesale orders",
    ],
    link: "/products/thyroid-self-test-kit",
  },
  {
    name: "Menopause Self Test Kit",
    image: "/assets/products/kit2.webp",

    description: [
      "to discover if you have entered the perimenopause phase.",
      "Collection method: Urine sample",
      "Contact us for wholesale orders",
    ],
    link: "/products/menopause-self-test-kit",
  },
];

//
export const treatment = [
  {
    id: "rybelsus",
    name: "rybelsus",
    images: [
      "/assets/products/Rybelsus-plus-strip-tablets.png",
      "/assets/products/Rybelsus-plus-strip-tablets2.png",
    ],
    description: [
      "Rybelsus is a once-daily tablet containing Semaglutide. It has clinically proven efficacy against weight loss and a recommended weight loss treatment option by the medical experts at Mayfair Weight Loss Clinic.",
      "Rybelsus is a one-daily tablet and should be taken on an empty stomach. Although it can be taken any time of the day, it should be taken at least 30 minutes before the first meal or drink of the day. If you eat right after taking Rybelsus, it will reduce its effectiveness. Therefore, taking Rybelsus first thing in the morning is recommended.Take tablets with a sip of water, only half a glass (around 120ml). Do not crush, split or chew tablets as this will reduce the absorption of the drug.If you miss a dose, skip this dose and take your normal dose the next day. If after 12 weeks on the maximum dose you have not lost at least 5% of your initial body weight, treatment should be discontinued and you should consult us or your own doctor for an alternative.",
    ],
  },
  {
    id: "ozempic",
    name: "ozempic",
    images: [
      "/assets/products/ozempic-1.png",
      "/assets/products/ozempic-2.png",
      "/assets/products/ozempic-3.png",
    ],
    description: [
      "Ozempic is a once-weekly Semaglutide injection and the recommended first-line treatment option for weight loss by the medical experts at Mayfair weight loss clinic.",
      "Ozempic is a once weekly pre-filled injectable pen. Attached the single-use needle which comes in the box, dial the dose to the required level and inject into the stomach or thigh. Each pen lasts 4 weeks.",
    ],
  },
  {
    id: "saxenda",
    name: "saxenda",
    images: [
      "/assets/products/saxenda1n.png",
      "/assets/products/saxenda1n2.png",
    ],
    description: [
      "Saxenda (liraglutide) is a prescription-only, once daily weight loss injection, licensed to treat those with obesity (BMI of above 30) or who are overweight (BMI of above 27 with additional weight related comorbidities). It contains the medicine liraglutide. It should be used alongside a healthy and balanced diet and exercise to help aid weight loss.",
      "It can be injected into your stomach area, upper leg or upper arm. Never inject into a vein or muscle. It can be administered at any time of day, independent of meal times.",
    ],
  },
  {
    id: "influenza-test-kit",
    name: "Flowflex Influenza Test Kit",
    images: ["/assets/products/kit1.webp"],
    description: [
      "This easy-to-use flu testing kit is designed for simple use at home and will tell you in just 15 minutes if the influenza virus has been detected in the sample you provide.",
      "Influenza is a contagious illness that many employers would prefer to keep away from their general workforce. A positive test result achieved at home may be enough to gain approval for a workplace absence, and in some cases may also lead to the intervention of medical professionals.",
      "This simple flu test will give you results quickly and easily and follows the same testing format as the COVID-19 lateral flow test that millions of people used during the height of the pandemic.",
    ],
  },
  {
    id: "menopause-self-test-kit",
    name: "Menopause Self Test Kit",
    images: [
      "/assets/products/kit2.webp",
      "/assets/products/kit2-2.webp",
      "/assets/products/kit2-3.webp",
      "/assets/products/kit2-4.webp",
    ],
    description: [
      "In just three minutes, this kit will measure for elevated levels of FSH to help determine whether you have entered the perimenopause stage.",
      "The menopause usually happens when women are between 45 and 55, though it can sometimes happen earlier. It denotes the point in time that a woman stops having periods.",
      "The run-up to the menopause is called the perimenopause, and this can last up to 10 years. Many early menopause symptoms can be caused by the perimenopause, including sleep disturbance, hot flashes/flushes, headaches, depression, anxiety, joint pain, vaginal dryness/pain and difficulty concentrating.",
      "The perimenopause can also cause changes in body shape as weight shifts to the stomach, thighs and hips.",
      "If you are experiencing these or any other menopausal symptoms, it can be helpful to know if you have entered the perimenopause stage, as this may be one way to rule out other causes for these symptoms.",
      "You may also wish to speak to your GP about treatment options if your test shows that your FSH levels are elevated (an indicator of the perimenopause).",
    ],
  },
  {
    id: "kidney-health-test-kit",
    name: "Kidney Health Self Test Kit",
    images: ["/assets/products/kit3.webp"],
    description: [
      "This kidney health at-home test kit from Newfoundland Diagnostics is an easy and convenient way to monitor the health of your kidneys from the comfort of your own home.",
      "It has been specifically designed to detect levels of microalbuminuria in the urine, which is an indicator of kidney damage.",
      "Microalbuminuria is the name given when small amounts of albumin, a protein normally found in the blood, leak into the urine. This can be a sign of early kidney damage, and if left untreated, can lead to chronic kidney disease or even kidney failure.",
      "By detecting microalbuminuria early, you can take steps to prevent further kidney problems and maintain your overall health.",
    ],
  },
  {
    id: "vitamin-d-self-test-kit",
    name: "Vitamin D Self Test Kit",
    images: ["/assets/products/kit4.webp"],
    description: [
      "This easy-to-use at-home Vitamin D Test Kit will give you a fast and accurate reading of how much Vitamin D is in your blood. It can be helpful to know your Vitamin D levels for a number of reasons.",
      "The first is the wide variety of physical conditions that have been linked to low vitamin D levels. These include osteoporosis, cardiovascular disease, diabetes, pregnancy complications, autoimmune diseases, strokes, cancers, Alzheimer’s, obesity and more.",
      "Knowing that you have low levels of vitamin D, coupled with symptoms that you have been struggling to get diagnosed, can help your GP get a clearer idea of your personal medical profile.",
      "Additionally, knowing that your vitamin D levels are low means you can often rapidly take steps to remedy the situation. There are many foods that are naturally high in Vitamin D, such as salmon, tuna, egg yolks and beef liver. Supplements can help, too.",
      "Low vitamin D levels may also provide you with the impetus you need to get outdoors more frequently. Vitamin D is known as the ‘sunshine vitamin’ because our bodies are able to create it when our skin is exposed to sunlight.",
      "Taking small steps to boost your levels will often put you back in the normal range. In doing so, you may feel like you are taking positive steps concerning your health and well being, as vitamin D plays a critical role in your nervous system, immune system and musculoskeletal system.",
    ],
  },
  {
    id: "HIV-Self-Test-Kit",
    name: "HIV Self Test Kit",
    images: ["/assets/products/kit5.webp"],
    description: [
      "This HIV test kit is designed to detect if you have the human immunodeficiency virus and should be performed under the supervision of a medical professional.",
      "By analysing a small sample of your blood taken from your finger, you can now test for HIV at home with help from a medical professional.",
      "Our at-home test kit has a very high accuracy rate (more than 99%) and gives results in 15-30 minutes. A negative result may provide a great deal of comfort to anyone who is concerned that they or their partner may have HIV.",
      "If the result suggests that you may be HIV positive, it is highly recommended that you consult your GP for confirmation, counselling and to discuss treatment options.",
    ],
  },
  {
    id: "uti-self-test-kit",
    name: "UTI Self Test Kit",
    images: ["/assets/products/kit11.webp"],
    description: [
      "This simple-to-use testing kit requires no medical assistance to undertake and will allow you to confirm whether or not you have a UTI (urinary tract infection) in just two minutes.",
      "It is very easy to worry when you have a UTI, but the truth is that they account for around 8 million visits to the doctor every year in the UK alone.",
      "Contrary to what many people believe, UTIs are not considered to be a sexually transmitted disease – UTIs can be caused by diabetes, constipation, feminine hygiene products, kidney stones, dehydration or ‘holding it in’ for a long time when you needed to urinate.",
      "There is certainly nothing to be embarrassed about if you have a UTI. In many cases, medication (antibiotics) will get rid of the infection within a few days.",
      "Some types of UTIs will go away on their own, and help to deal with discomfort can be provided by over-the-counter painkillers.",
    ],
  },
  {
    id: "thyroid-self-test-kit",
    name: "Thyroid Self Test Kit",
    images: [
      "/assets/products/kit10.webp",
      "/assets/products/kit102.webp",
      "/assets/products/kit103.webp",
      "/assets/products/kit104.webp",
    ],
    description: [
      "This home Thyroid Testing Kit measures levels of TSH in the blood to help you ascertain if these are elevated or not.",
      "Thyroid stimulating hormone – which is also known as thyrotropin, thyrotrophic hormone, TSH, or hTSH – is a pituitary hormone that stimulates the thyroid gland to produce thyroxine, and then triiodothyronine, which stimulates the metabolism of almost every tissue in the body. ",
      "When levels of TSH are raised, this may indicate hypothyroidism – a condition caused by the thyroid gland not making enough thyroid hormones. Symptoms of an underactive thyroid include unexplained weight gain, tiredness, depression and loss of libido.",
      "Knowing that your TSH levels are high can help to start discussions with your GP about a possible cause for your symptoms. At home thyroid tests can be one of the quickest ways to investigate this possibility and can be done in your own time.",
    ],
  },
  {
    id: "drugs-and-alcohol-self-test-kit",
    name: "drugs and alcohol self test kit",
    images: ["/assets/products/kit7.webp"],
    description: [
      "This easy-to-use test kit provides rapid results that indicate whether a person has recently used any of an array of common drugs or has consumed alcohol.",
      "Drug and alcohol tests have become a routine part of many jobs and are also sometimes undertaken in other settings, such as schools, universities or on the sports field.",
      "Our at-home test kit simplifies the process by swapping urine samples for a saliva sample – which can be done in the presence of the tester without fear of embarrassment (or third party interference).",
      "The test uses what is known as monoclonal antibodies to identify elevated levels of specific drugs and alcohol in saliva.",
      "It can help to prevent alcohol misuse, identify drug users and provide comfort to business leaders in safety-critical industries such as construction, public transport and the armed forces.",
    ],
  },
  {
    id: "ulcer-self-test-kit",
    name: "Stomach Ulcer Self Test Kit",
    images: ["/assets/products/kit8.webp"],
    description: [
      "This simple at-home stomach ulcer testing kit has been developed to assess whether or not there is evidence of Helicobacter (H. pylori) bacteria in your body.",
      "It checks a small sample of your faeces to find traces of the bacteria, and a positive result can be interpreted as a sign that a gastric (stomach) ulcer may be present. You can also get ulcers in part of the intestine just beyond the stomach, which are called duodenal ulcers.",
      "Stomach ulcers and duodenal ulcers (sometimes called peptic ulcers) cause the same symptoms and the treatment for both is the same.",
    ],
  },
  {
    id: "iron-deficiency-self-test-kit",
    name: "Iron Deficiency Self Test Kit",
    images: [
      "/assets/products/kit9.webp",
      "/assets/products/kit92.webp",
      "/assets/products/kit93.webp",
    ],
    description: [
      "This at-home Iron Deficiency Test Kit will let you see in just five minutes if there are low iron levels in your blood.",
      "Iron is an essential metal in the body that helps to move oxygen through the bloodstream and aids in cell multiplication.",
      "When people have a lack of iron in their body, the cause is worth investigating as it could be caused by blood loss through internal bleeding. Additionally, low levels of iron are synonymous with anaemia, a condition in which the body doesn’t have enough healthy red blood cells.",
      "The symptoms of iron depletion and iron deficiency anaemia include extreme fatigue, headaches, difficulty concentrating, muscle and joint pain, weight gain, pale skin, heart palpitations and, in some cases, weakness, chest pain, shortness of breath and sleep problems.",
      "The results of our rapid diagnostic test will be displayed in just 5 minutes. A normal result (two lines can be seen in the window) indicates a normal level of iron in the blood.",
      "This test kit will enable you to seek out further medical advice and tests if the result comes back as ‘abnormal’. An abnormal reading – which can be seen when only ONE red line is visible (next to the letter ‘C’) – indicates that the ferritin levels in your blood are low.",
      "This may be evidence of an iron deficiency and may also indicate the possibility of anaemia. This kit does not test for an excess of iron.",
    ],
  },
  {
    id: "bowel-health-self-test-kit",
    name: "Bowel Health Self Test Kit",
    images: [
      "/assets/products/kit12.webp",
      "/assets/products/kit12-2.webp",
      "/assets/products/kit12-3.webp",
      "/assets/products/kit12-4.webp",
    ],

    description: [
      "This simple at-home bowel health test kit is designed to detect blood in your stool. If blood is detected, a consultation with your GP is advised so that further tests can be carried out to identify what’s wrong.",
      "There are many diseases and conditions that can result in blood in the faeces – bowel cancer among them. A positive result for blood in the stool gives GPs the opportunity to get to the root of the problem.",
      "Among the many gastrointestinal problems that can cause blood in the stool are ulcers, polyps, diverticulitis, fissures and colon cancer. When detected early, around 90 per cent of colon and bowel cancers cases can be successfully treated.",
    ],
  },
  {
    id: "prostate-health-self-test-kit",
    name: "Prostate Health Self Test Kit",
    images: ["/assets/products/kit13.webp"],
    description: [
      "This kit is designed to be used at home with help from a medical professional or in a medical facility such as a clinic. It detects PSA levels in the blood.",
      "By analysing just a small drop of your blood taken from your finger, you can test to see if there is evidence of heightened levels of PSA in your sample. PSA is the most useful and meaningful tumour marker for prostate cancer and prostate infection of benign prostatic hyperplasia (BPH).",
      "Our at-home test kit has a very high accuracy rate (almost 99%) and gives results in just five minutes. A positive result should be taken to your doctor for further investigation.",
      "This test makes use of a combination of colloidal gold conjugate and anti-PSA antibodies to selectively detect total PSA in whole blood, serum or plasma. The test has a cut-off value of 4ng/ml.",
      "The test’s membrane is pre-coated with PSA antibodies on the test line region. During testing, the specimen reacts with the particle coated with anti-PSA antibody. The mixture migrates upward on the membrane chromatographically by capillary action to react with anti-PSA antibodies on the membrane and generate a coloured line.",
      "To serve as a procedural control, a coloured line will always appear in the control line region (C) indicating that the proper volume of specimen has been added and membrane wicking has occurred.",
    ],
  },
  {
    id: "ovulation-self-test-kit",
    name: "Ovulation Self Test Kit",
    images: ["/assets/products/kit14.webp", "/assets/products/kit14-2.webp"],
    description: [
      "This easy-to-use Ovulation Test Kit will help you to detect the peak level of luteinising hormone in your body. This is the time that pregnancy is most likely to occur.",
      "When luteinising hormone (LH) levels are at their peak, this is an indication of peak fertility – it is in this window that you have the best chance of conceiving.",
      "This window is surprisingly short. Ovulation involves the release of an egg from the ovaries – it then passes to the fallopian tube where it awaits fertilisation. It must be fertilised by sperm within 24 hours after its release for pregnancy to occur.",
      "Immediately before the egg is released, large quantities of luteinising hormone (LH) are released into the body – this actually triggers the release of the egg. It is sometimes known as an ‘LH surge’, and as heightened LH levels can be detected in urine, it can be a powerful indicator of the best time to try and conceive.",
      "This surge normally takes place in the middle of the menstrual cycle.",
      "Please note that not all menstrual cycles result in ovulation – nor does the LH surge occur in every instance.",
    ],
  },
  {
    id: "pregnancy-self-test-kit",
    name: "Pregnancy Self Test Kit",
    images: ["/assets/products/kit15.webp", "/assets/products/kit15-2.webp"],
    description: [
      "This kit is one of the quickest and simplest home pregnancy tests around, with results seen in just three minutes.",
      "A missed period is one of the first possible signs you may be pregnant, especially if you have been actively trying for a baby. A pregnancy test is often the next step.",
      "This kit has been designed to test a sample of your urine to ascertain whether human chorionic gonadotropin hCG levels are heightened – a key indicator of pregnancy. hCG is sometimes known as the ‘pregnancy hormone’.",
      "Our kit is designed to test for the qualitative detection of hCG and uses a combination of antibodies including monoclonal hCG antibodies to selectively detect elevated levels.",
      "It is one of the most sensitive tests available, with a more than 99% accuracy rate. A positive result – if you are indeed pregnant – can be seen in just three minutes.",
    ],
  },
  {
    id: "male-fertility-self-test-kit",
    name: "Male Fertility Self Test Kit",
    images: [
      "/assets/products/kit16.webp",
      "/assets/products/kit16-2.webp",
      "/assets/products/kit16-3.webp",
    ],
    description: [
      "In just five minutes, this Male Fertility Test Kit will allow you to see whether or not the concentration of sperm in your sample is within what is perceived to be a normal range or not.",
      "One of the most critical factors when trying to conceive is sperm concentration in semen. A low sperm count will make conception difficult, if not impossible.",
      "For this reason, sperm concentration is closely monitored by doctors when couples are finding it hard to conceive.",
      "Male infertility is common, affecting around 7% of men. A low sperm count is not always the issue, however, as it is possible to have a sperm count within the normal range but in which the sperm are weak, inactive or deformed in some way.",
    ],
  },
];
