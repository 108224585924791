import React from "react";
import Header from "../components/header/Header";
import HomeProducts from "../components/homeProducts/HomeProducts";
import Contact from "../components/contact/Contact";
import Services from "../components/services/Services";
import Footer from "../components/footer/Footer";
import Weightloss from "../components/weightloss/Weightloss";
import Treatments from "../components/treatments/Treatments";

const Home = () => {
  return (
    <>
      <Header />
      <Services />
      {/* <Treatments /> */}
      <HomeProducts />
      <Weightloss />

      <Contact />
      <Footer />
    </>
  );
};

export default Home;
