import "./header.scss";
import Nav from "../nav/Nav";
import HeaderSwiper from "../headerSwiper/HeaderSwiper";

const Header = () => {
  return (
    <div className="header">
      <Nav />
      <HeaderSwiper />
    </div>
  );
};

export default Header;
