import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { treatment } from "../../data";
import Nav from "../../components/nav/Nav";
import Contact from "../../components/contact/Contact";
import Footer from "../../components/footer/Footer";
import "./product.scss";

const Product = () => {
  const param = useParams().treatment;

  const details = treatment.find((el) => el.id === param);
  console.log(details);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (!details) {
      return navigate("/");
    }
  }, [details, navigate]);

  const [curImg, setcurImg] = useState(0);
  return (
    <>
      <div className="details--container">
        <Nav />

        <div className="details">
          <div className="left">
            <div className="product-slider">
              <div className="swiper-slide">
                <img src={details.images[curImg]} alt={details.name} />
              </div>
              <div className="slider-thumbs">
                {details.images.map((img, i) => (
                  <div
                    className={i === curImg ? "thumbs thumb-active" : "thumbs"}
                    onClick={() => setcurImg(i)}
                    key={i}
                  >
                    <img src={img} alt={details.name} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="right">
            <h3>{details.name}</h3>
            {details.description.map((p, i) => (
              <p key={i}>{p}</p>
            ))}
            <a href="#contact">
              <button className="btn">Enquire with Us</button>
            </a>
          </div>
        </div>
      </div>
      <Contact />
      <Footer />
    </>
  );
};

export default Product;
